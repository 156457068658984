import "./intro.css";
import Me from "../../img/me.png";

const Intro = () => {
  return (
    <div className="intro">
      <div className="intro-left">
        <div className="left-wrapper">
          <h2 className="caption">Hello, My name is</h2>
          <h1 className="name">Naya</h1>
          <div className="title">
            <div className="title-wrapper">
              <div className="title-item">Software Engineer</div>
              <div className="title-item">Front End Engineer</div>
              <div className="title-item">Back End Engineer</div>
              <div className="title-item">Designer</div>
            </div>
          </div>
          <p className="desc">
            I design and develop services for customers of all sizes,
            specializing in creating stylish, modern websites, web services and
            online stores.
          </p>
        </div>
        <svg
          width="75"
          height="75"
          viewBox="0 0 75 75"
          fill="none"
          stroke="red"
          className="i-scroll"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="scroll">
            <path
              id="Vector"
              d="M40.5 15L34.5 9L28.5 15"
              strokeWidth="3"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
      <div className="intro-right">
        <div className="i-bg"></div>
        <img src={Me} alt="" className="i-img" />
      </div>
    </div>
  );
};

export default Intro;
