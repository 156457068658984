import "./about.css";
import Code from "../../img/code.jpg";

const About = () => {
  return (
    <div className="about">
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img src={Code} alt="" className="a-img" />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub">
          An experienced Full Stack Web/Software Developer with a background in
          Architectural Technology, using JavaScript/ Node.JS/React which offers
          an engaging challenge to continually learn and improve my skills in
          creating high-quality software.
        </p>
        <p className="a-desc">
          Having experiences from a broad variety of industries, I can
          creatively apply specialized knowledge to make decisions and solve
          complex problems as demonstrated in technical and leadership roles
          I've played. I always aim to write clean, readable, fast, and scalable
          code with emphasis on security and performance.
        </p>
      </div>
    </div>
  );
};

export default About;
