export const products = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-accf6.appspot.com/o/rovigo.png?alt=media&token=6e71c53b-39bf-4e36-9075-20aac6919234",
    link: "https://rovigo.vercel.app",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-accf6.appspot.com/o/shop.png?alt=media&token=53d9a52d-325d-4c16-84f4-918bfe6c9832",
    link: "https://shop-place.vercel.app/",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-accf6.appspot.com/o/freelancer.png?alt=media&token=e4def7b9-521f-47f0-938b-449df022a4c6",
    link: "https://freelancer-ca.vercel.app/",
  },
];
